import React, { useState } from "react";
import "./Contacto.css"; // Asegúrate de tener el archivo CSS correspondiente

function Contacto() {
  const [formData, setFormData] = useState({
    nombre: "",
    telefono: "",
    objetivo: "",
    aceptarTerminos: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData({ ...formData, [name]: newValue });
  };

  const handleEnviarCorreo = () => {
    const { nombre, telefono, objetivo, aceptarTerminos } = formData;

    if (nombre && telefono && objetivo && aceptarTerminos) {
      const subject = "Solicitud de consulta";
      const body = `Nombre del paciente: ${nombre}%0D%0A%0D%0ANúmero de teléfono: ${telefono}%0D%0A%0D%0AObjetivo de la consulta: ${objetivo}`;
      const correoDestino = "dra.janirethpineda@gmail.com";

      window.location.href = `mailto:${correoDestino}?subject=${subject}&body=${body}`;
    } else {
      // Agregar una lógica para manejar el caso en que no se cumplan las validaciones
      alert(
        "Por favor, complete todos los campos y acepte los términos de política de privacidad."
      );
    }
  };

  const isButtonDisabled = !(
    formData.nombre &&
    formData.telefono &&
    formData.objetivo &&
    formData.aceptarTerminos
  );

  return (
    <div className="contacto-container" id="contacto">
      <h2>Contacto</h2>
      <p>¡Contáctanos para cualquier consulta o comentario!</p>
      <form className="contact-form">
        <div className="form-group">
          <label htmlFor="nombre">Nombre:</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefono">Número de Teléfono:</label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="objetivo">Objetivo de la Consulta:</label>
          <textarea
            id="objetivo"
            name="objetivo"
            value={formData.objetivo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              name="aceptarTerminos"
              checked={formData.aceptarTerminos}
              onChange={handleChange}
            />{" "}
            Acepto los términos de política de privacidad
          </label>
        </div>
        <button
          type="button"
          onClick={handleEnviarCorreo}
          disabled={isButtonDisabled}
          className={
            isButtonDisabled ? "boton-deshabilitado" : "boton-habilitado"
          }
        >
          {isButtonDisabled ? "Campos incompletos" : "Enviar Correo"}
        </button>
      </form>
    </div>
  );
}

export default Contacto;
