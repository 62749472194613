import React from "react";
import "./WAContact.css";
import whatsappImage from "../../images/wa.webp"; // Reemplaza con la ruta correcta de tu imagen de WhatsApp

function WAContact() {
  // Número de WhatsApp al que se enviará el mensaje
  const phoneNumber = "7671134574"; // Reemplaza con tu número de WhatsApp

  // Mensaje predeterminado
  const message = "¡Hola! Estoy interesado en agendar una cita.";

  // URL de WhatsApp con el número y el mensaje predefinido
  const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    message
  )}`;

  return (
    <div className="wa-contact" id="cita">
      <a
        href={whatsappURL}
        target="_blank"
        rel="noopener noreferrer"
        className="wa-link"
      >
        <div className="wa-image">
          <img src={whatsappImage} alt="WhatsApp" /> {/* Imagen de WhatsApp */}
        </div>
        <div className="wa-text">
          <p>¡Contáctanos por WhatsApp para agendar una cita!</p>
        </div>
        <div className="wa-button">
          <button className="button">Contáctanos</button>
        </div>
      </a>
    </div>
  );
}

export default WAContact;
