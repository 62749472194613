import Contacto from "../business/Contacto/Contacto";
import NavBar from "../business/NavBar/NavBar";
import WAContact from "../business/WhatsAppContact/WAContact";
import Agenda from "./Agenda/Agenda";
import Banner from "./Banner/Banner";
import Footer from "./Footer/Footer";
import Profile from "./Profile/Profile";
import Services from "./Services/Services";
import Social from "./Social/Social";

const Home = () => {
  return (
    <>
      <NavBar />
      <Banner id="inicio" />
      <Profile id="perfil" />
      <WAContact id="cita" />
      <Services id="servicios" />
      <Contacto id="contacto" />
      <Agenda id="agenda" />
      <Social id="redes" />
      <Footer />
    </>
  );
};

export default Home;
