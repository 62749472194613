// Footer.js
import React from "react";
import "./Footer.css";
import logo from "../../images/logo.png"; // Importa la imagen del logo

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-row">
        <div className="footer-title">Dra Jany Pineda</div>
      </div>
      <div className="footer-row">
        <div className="footer-section">
          <a href="#inicio" className="nav-link">
            Inicio
          </a>
        </div>
        <div className="footer-section">
          <a href="#perfil" className="nav-link">
            Perfil Profesional
          </a>
        </div>
        <div className="footer-section">
          <a href="#cita" className="nav-link">
            Cita
          </a>
        </div>
        <div className="footer-section">
          <a href="#servicios" className="nav-link">
            Servicios Médicos
          </a>
        </div>
        <div className="footer-section">
          <a href="#contacto" className="nav-link">
            Contacto
          </a>
        </div>
        <div className="footer-section">
          <a href="#agenda" className="nav-link">
            Agenda
          </a>
        </div>
      </div>
      <div className="footer-row">
        <img src={logo} alt="Logo del diseñador" className="footer-logo" />
      </div>
      <div className="footer-row">
        <div className="footer-copyright">
          @ Todos los derechos reservados - {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
