import { Routes, Route } from "react-router-dom";
import Home from "./presentation/Home";
import "./App.css";
import Contacto from "./business/Contacto/Contacto";
import Profile from "./presentation/Profile/Profile";
import WAContact from "./business/WhatsAppContact/WAContact";
import Services from "./presentation/Services/Services";
import Agenda from "./presentation/Agenda/Agenda";
import Social from "./presentation/Social/Social";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/perfil" element={<Profile />}></Route>
      <Route path="/cita" element={<WAContact />}></Route>
      <Route path="/servicios" element={<Services />}></Route>
      <Route path="/contacto" element={<Contacto />}></Route>
      <Route path="/agenda" element={<Agenda />}></Route>
      <Route path="/redes" element={<Social />}></Route>
    </Routes>
  );
}

export default App;
