import React from "react";
import "./Social.css"; // Asegúrate de tener el archivo CSS correspondiente
import FacebookIcon from "./../../images/facebook-icon.png"; // Ruta a la imagen del ícono de Facebook
import InstagramIcon from "./../../images/instagram-icon.png"; // Ruta a la imagen del ícono de Instagram

function Social() {
  return (
    <div className="social-container" id="redes">
      <div className="social-item">
        <a
          href="https://www.facebook.com/profile.php?id=100092267357983"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={FacebookIcon} alt="Ícono de Facebook" />
          <p>@dra.janypineda</p>
        </a>
      </div>
      <div className="social-item">
        <a
          href="https://www.instagram.com/dra.janypineda/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={InstagramIcon} alt="Ícono de Instagram" />
          <p>@dra.janypineda</p>
        </a>
      </div>
    </div>
  );
}

export default Social;
