import React from "react";
import "./Banner.css";

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1 className="banner-slogan">¡Al cuidado de tu salud!</h1>
        <p className="banner-description">Conoce a la Dra. Jany Pineda</p>
      </div>
    </div>
  );
};

export default Banner;
