import React from "react";
import "./Profile.css";
import profileImage from "../../images/profile.jpg"; // Reemplaza con la ruta correcta de la imagen de perfil

function Profile() {
  return (
    <section className="profile" id="perfil">
      <div className="profile-container">
        <div className="profile-image">
          {/* Utiliza la imagen importada para el perfil */}
          <img src={profileImage} alt="Profile" />
        </div>
        <div className="profile-content">
          <h2>Perfil Profesional</h2>
          <p>
            La Dra. Jany Pineda es una profesional con años de experiencia en
            ginecologia, obstetricia y uroginecologia. Su dedicación y
            compromiso con la salud de sus pacientes la convierten en una
            experta en su campo.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Profile;
