import React, { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? "active" : ""}`}>
      <div className="navbar-container">
        <span className="navbar-text">Dra. Jany Pineda</span>
        <div
          className={`menu-icon ${isOpen ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </div>
        <ul className={`nav-menu ${isOpen ? "active" : ""}`}>
          <li className="nav-item">
            <a href="#inicio" className="nav-link">
              Inicio
            </a>
          </li>
          <li className="nav-item">
            <a href="#perfil" className="nav-link">
              Perfil Profesional
            </a>
          </li>
          <li className="nav-item">
            <a href="#cita" className="nav-link">
              Cita
            </a>
          </li>
          <li className="nav-item">
            <a href="#servicios" className="nav-link">
              Servicios Médicos
            </a>
          </li>
          <li className="nav-item">
            <a href="#contacto" className="nav-link">
              Contacto
            </a>
          </li>
          <li className="nav-item">
            <a href="#agenda" className="nav-link">
              Agenda
            </a>
          </li>
          <li className="nav-item">
            <a href="#redes" className="nav-link">
              Redes Sociales
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
