import React from "react";
import "./Agenda.css";
import locationIcon from "./../../images/location-icon.png";
import clockIcon from "./../../images/clock-icon.png";
import phoneIcon from "./../../images/phone-icon.png";

function Agenda() {
  const phoneNumber = "7671134574";

  return (
    <div className="agenda-container" id="agenda">
      <div className="agenda-section">
        <div className="section-icon">
          <img src={locationIcon} alt="Ícono de Dirección" />
        </div>
        <div className="section-content">
          <h3>Dirección</h3>
          <p>Cd. Altamirano, Guerrero</p>
        </div>
      </div>

      <div className="agenda-section">
        <div className="section-icon">
          <img src={clockIcon} alt="Ícono de Horario" />
        </div>
        <div className="section-content">
          <h3>Horario</h3>
          <p>Lunes a Sábado</p>
          <p>8 am- 2 pm y 4 pm- 7 pm</p>
          <p>
            <b>Urgencias las 24 hrs</b>
          </p>
        </div>
      </div>

      <div className="agenda-section">
        <div className="section-icon">
          <img src={phoneIcon} alt="Ícono de Contacto" />
        </div>
        <div className="section-content">
          <h3>Contacto</h3>
          <p>
            Correo: <b>dra.janirethpineda@gmail.com </b>
          </p>
          <p>
            Teléfono: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Agenda;
