import React, { useState } from "react";
import "./Services.css";

const Services = () => {
  const [expandedService, setExpandedService] = useState(null);

  const servicesData = [
    {
      title: "Obstetricia",
      description: [
        "Control prenatal",
        "Embarazo normal",
        "Embarazo en adolescente",
        "Embarazo de alto riesgo",
        "Alteraciones endocrinas durante el embarazo:",
        "- Hipo o hipertiroidismo",
        "- Diabetes gestacional o DM2",
        "Alteraciones metabólicas:",
        "- Sobrepeso u obesidad",
        "- Hipertensión arterial",
        "Hipertensión gestacional vs Preeclampsia",
        "TORCH",
        "Pérdida gestacional recurrente",
        "Síndrome de Anticuerpos Antifosfolípidos (SAAF)",
        "Lupus eritematoso sistémico (LES)",
        "Litiasis en el embarazo",
        "Manejo del aborto",
        "Atención del parto",
        "Atención de la cesárea",
      ],
    },
    {
      title: "Ginecología",
      description: [
        "Abordaje de las alteraciones menstruales:",
        "- Ciclos menstruales irregulares",
        "- Amenorrea y Dismenorrea",
        "Abordaje de la paciente y pareja con alteración de la fertilidad",
        "Manejo médico del sangrado uterino anormal por:",
        "- Adenomiosis",
        "- Endometriosis",
        "- Miomatosis uterina",
        "- Pólipos endometriales",
        "- Hiperplasia endometrial",
        "Orientación sobre métodos anticonceptivos",
        "- Colocación o retiro de DIU",
        "- Colocación o retiro de implante subdérmico",
        "- Hormonales orales, dérmicos o inyectables",
        "Detección de enfermedades de transmisión sexual",
        "Detección oportuna de cáncer cervicouterino:",
        "- Citología cervicovaginal",
        "- Colposcopia",
        "- PCR para VPH",
        "Tratamiento de verrugas genitales y condilomas por VPH",
        "Vacunación para VPH",
        "Detección oportuna de cáncer de mama (exploración mamaria)",
        "Check Up ginecológico: exploración ginecológica + ultrasonido ginecológico abdominal y vaginal citología cervicovaginal. colposcopia exploración mamaria",
        "Manejo del climaterio y menopausia",
      ],
    },
    {
      title: "Uroginecologia",
      description: [
        "Diagnóstico:",
        "- Litiasis urinaria",
        "- Prolapso de órganos pélvicos (POP)",
        "- Incontinencia urinaria (IU) de esfuerzo, urgencia o mixta",
        "- IU oculta",
        "- Trastornos del vaciamiento urinario",
        "- Incontinencia anal y fecal",
        "- Trastornos de la defecación (disinergia defecatoria)",
        "- Tumoraciones vaginales",
        "+ Quistes suburetrales",
        "+ Quiste de Gartner",
        "+ Quistes de inclusión",
        "+ Quiste de la glándula de Bartholin",
        "- Alteraciones de la diferenciación sexual",
        "- Clitoromegalia",
        "- Fusión de labios mayores",
        "- Tabiques vaginales",
        "- Malformaciones müllerianas",
        "+ Síndrome de Mayer Rokitansky Kuster Hauser (MRKH)",
        "+ Sindrome de Wunderlich",
        "- Sindrome Genitourinario de la Menopausia (SGUM)",
        "- Hematuria",
        "- Sindrome doloroso vesical",
        "- Sindrome de dolor pélvico crónico",
        "- Cistitis",
        "- Sindrome de vejiga hiperactiva",
        "Tratamiento conservador:",
        "- Rehabilitación de piso pélvico femenino",
        "+ Ejercicios de Kegel",
        "+ Ejercicios hipopresivos",
        "+ Terapia de electroestimulación y Biofeedback",
        "- Terapia conductual",
        "- Terapia estrogénica vaginal",
        "- Pesarios vaginales",
        "- Cateterismo urinario",
        "Tratamiento quirúrgico:",
        "- Cistoscopia",
        "- Colocación de cateteres ureterales",
        "- Cirugía de corrección de POP: Reparación sitio especifico",
        "- Histerectomía vaginal",
        "Sacrocolpopexia",
        "- Cirugía antiincontinencia; TOT, TVT, TVT-O, Burch Resección y extirpación de quistes vaginales",
        "-Resección de tabiques vaginales",
        "- Labioplastia",
        "Creación de Neovagina",
      ],
    },
    // ... otros servicios
  ];

  const handleServiceClick = (index) => {
    setExpandedService((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="services-wrapper">
      <div className="services-container" id="servicios">
        <h2>Servicios Médicos</h2>
        <ul className="services-list">
          {servicesData.map((service, index) => (
            <li key={index} className="service-item">
              <div
                className="service-header"
                onClick={() => handleServiceClick(index)}
              >
                <h3>{service.title}</h3>
                <div className="expand-icon">
                  {expandedService === index ? "-" : "+"}
                </div>
              </div>
              <ul
                className={`service-description ${
                  expandedService === index ? "active" : ""
                }`}
              >
                {service.description.map((point, i) => {
                  let pointClass = "";
                  if (point.startsWith("- ")) {
                    pointClass = "sub-point";
                  } else if (point.startsWith("+ ")) {
                    pointClass = "third-point";
                  }
                  return (
                    <li key={i} className={pointClass}>
                      {point.replace(/^[+-]\s*/, "")}
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Services;
